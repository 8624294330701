import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAllPermsPipe } from './has-all-perms.pipe';
import { HasEitherPermPipe } from './has-either-perm.pipe';
import { HasAllGroupsPipe } from './has-all-groups.pipe';
import { HasEitherGroupPipe } from './has-either-group.pipe';

@NgModule({
  declarations: [
    HasAllPermsPipe,
    HasEitherPermPipe,
    HasAllGroupsPipe,
    HasEitherGroupPipe,
  ],
  imports: [CommonModule],
  exports: [
    HasAllPermsPipe,
    HasEitherPermPipe,
    HasAllGroupsPipe,
    HasEitherGroupPipe,
  ],
  providers: [
    HasAllPermsPipe,
    HasEitherPermPipe,
    HasAllGroupsPipe,
    HasEitherGroupPipe,
  ],
})
export class PipesModule {}
