import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasEitherPerm',
})
export class HasEitherPermPipe implements PipeTransform {
  transform(user: any, perms: Array<string>): unknown {
    if (!user) return false;
    let ret = false;
    perms.forEach((g) => {
      if (user.perms.filter((e) => e === g).length > 0) {
        ret = true;
      }
    });
    return ret;
  }
}
