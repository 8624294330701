import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasEitherGroup',
})
export class HasEitherGroupPipe implements PipeTransform {
  transform(user: any, groups: Array<string>): boolean {
    if (!user) return false;
    let ret = false;
    groups.forEach((g) => {
      if (user.groups.filter((e) => e.name == g).length > 0) {
        ret = true;
      }
    });
    return ret;
  }
}
